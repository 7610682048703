<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-email-contact', params: { accountId: this.$route.params.accountId } }">Email Contacts</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ emailContact.email }}</h1>
                <!-- <p class="text-caption text-center">Email contact overview</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="emailContact">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-end mb-0 text-caption grey--text text--darken-1">Contact {{ emailContact.id }}</p>
                    <v-card>
                        <v-app-bar color="teal darken-2" dark flat dense>
                            <v-app-bar-title>Email contact</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-2">Email</p>
                            <p class="mb-0 pb-0">
                                {{ emailContact.email }}
                                <v-btn icon color="teal darken-2" @click="editEmailContactEmail">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Alias</p>
                            <p class="mb-0 pb-0">
                                {{ emailContact.alias }}
                                <v-btn icon color="teal darken-2" @click="editEmailContactAlias">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p> -->

                            <p class="text-overline mb-0 mt-8">Topics</p>
                            <template v-if="optinTopicList.length === 0">
                            <p class="mb-0 pb-0">
                                This contact is not subscribed to any topics and will not receive any emails.
                            </p>
                            </template>
                            <template v-if="optinTopicList.length > 0">
                            <p class="mb-0 pb-0">
                                This contact opted in to the following topics:
                            </p>
                            <v-chip v-for="topic in optinTopicList" :key="topic.id" class="mx-1" small>
                                <!-- <font-awesome-icon :icon="['fas', 'check']"/> -->
                                {{ topic.label }}
                            </v-chip>
                            </template>
                            <template v-if="optoutTopicList.length > 0">
                            <p class="mb-0 pb-0">
                                This contact opted out of the following topics:
                            </p>
                            <v-chip v-for="topic in optoutTopicList" :key="topic.id">
                                <!-- <font-awesome-icon :icon="['fas', 'cross']"/> -->
                                {{ topic.label }}
                            </v-chip>
                            </template>

                            <!-- <p class="text-overline mb-0 mt-8">Email Contact ID</p>
                            <p class="mb-0 pb-0">
                                {{ emailContact.id }}
                            </p> -->

                            <!-- TODO: how many lists or topics is this contact subscribed to? which ones? -->
                            <!-- <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0">
                                <span v-if="affectedEmailTemplateList.length > 0">
                                    This email contact is referenced by {{ affectedEmailTemplateList.length }} templates.
                                </span>
                                <span v-if="affectedEmailTemplateList.length === 0">
                                This email contact is not currently being used by any email templates.
                                </span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, emailContactId: this.$route.params.emailContactId } }">Access control</router-link>
                            </p> -->
                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                            <p class="mb-0 pb-0">
                            </p> -->
                            <p class="text-overline mb-0 mt-8">Email Audiences</p>
                            <p class="text-caption mb-4 mt-0">The contact is part of the following email audiences.</p>
                        </v-card-text>
                        <EmailAudienceContactList :emailContactId="this.$route.params.emailContactId" @selected="onSelectEmailAudience"/>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Email Contact Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.emailContact, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editEmailContactEmailDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            This is the address used to send email to this contact.
                            You can change it at any time.
                        </p>

                        <!-- <v-row no-gutters align="center"> -->
                            <!-- <v-text-field
                                ref="emailContactAliasInput"
                                v-model="editableEmailContactAlias"
                                label="Alias"
                                :rules="newEmailContactAliasRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field> -->
                            <v-text-field
                                ref="emailContactEmailInput"
                                v-model="editableEmailContactEmail"
                                label="Email"
                                :rules="newEmailContactEmailRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                        <!-- </v-row> -->
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailContactEmail" :disabled="!isEditEmailContactEmailComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailContactEmailDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailContactAliasDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the contact alias</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The alias is the way you address the contact in your communications.
                            We call it an alias because it's not necessarily the contact's full name
                            or even their real name. It could be just a first name, or a nickname.
                            For example, "Hello, Sparky!".
                            You can change it at any time.
                        </p>
                        <v-text-field v-model="editableEmailContactAlias" label="Contact alias"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailContactAlias" :disabled="!isEditEmailContactAliasComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailContactAliasDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.row > .v-input:first-of-type {
    margin-right: 4px;
}
.row > .v-input + .v-input {
    margin-left: 4px;
    margin-right: 4px;
}
.row > .v-input:last-of-type {
    margin-left: 4px;
}
</style>

<script>
import { mapState } from 'vuex';
import EmailAudienceContactList from '@/components/account-dashboard/EmailAudienceContactList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        EmailAudienceContactList,
    },
    data: () => ({
        affectedEmailTemplateList: [],

        error: null,
        account: null,
        emailContact: null,
        topicList: null,
        invite: null,
        // edit form title dialog
        editEmailContactEmailDialog: false,
        editEmailContactAliasDialog: false,
        editableEmailContactAlias: null,
        editableEmailContactEmail: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.emailContact !== null;
        },
        isEditEmailContactEmailComplete() {
            return typeof this.editableEmailContactEmail === 'string' && this.editableEmailContactEmail.trim().length > 0;
        },
        isEditEmailContactAliasComplete() {
            return typeof this.editableEmailContactAlias === 'string' && this.editableEmailContactAlias.trim().length > 0;
        },
        optinTopicList() {
            if (Array.isArray(this.topicList)) {
                return this.topicList.filter((item) => item.permit);
            }
            return [];
        },
        optoutTopicList() {
            if (Array.isArray(this.topicList)) {
                return this.topicList.filter((item) => !item.permit);
            }
            return [];
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadEmailContact() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailContact: true });
                const response = await this.$client.account(this.$route.params.accountId).emailContact.get(this.$route.params.emailContactId);
                console.log(`loadEmailContact: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailContact = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.alias ?? '';
                    // this.emailContact.alias ??= '';
                    // this.emailContact.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email contact');
                }
            } catch (err) {
                console.error('failed to load email contact', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailContact: false });
            }
        },
        async loadTopicList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTopicList: true });
                const response = await this.$client.account(this.$route.params.accountId).emailContactPermit.search({ email_contact_id: this.$route.params.emailContactId });
                console.log(`loadTopicList: response ${JSON.stringify(response)}`);
                if (response.list) {
                    this.topicList = response.list;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email contact permits');
                }
            } catch (err) {
                console.error('failed to load email contact permits', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTopicList: false });
            }
        },
        async save(contactAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditEmailContact: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).emailContact.edit({ id: this.$route.params.emailContactId }, contactAttr);
                console.log(`saveEditEmailContact: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email contact' });
                return false;
            } catch (err) {
                console.error('failed to edit email contact', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email contact' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditEmailContact: false });
            }
        },
        editEmailContactEmail() {
            this.editEmailContactEmailDialog = true;
            this.editableEmailContactEmail = this.emailContact.email;
        },
        editEmailContactAlias() {
            this.editEmailContactAliasDialog = true;
            this.editableEmailContactAlias = this.emailContact.alias;
        },
        async saveEditEmailContactEmail() {
            const isEdited = await this.save({ email: this.editableEmailContactEmail });
            this.editEmailContactEmailDialog = false;
            if (isEdited) {
                this.$set(this.emailContact, 'email', this.editableEmailContactEmail);
            }
        },
        async saveEditEmailContactAlias() {
            const isEdited = await this.save({ alias: this.editableEmailContactAlias });
            this.editEmailContactAliasDialog = false;
            if (isEdited) {
                this.$set(this.emailContact, 'alias', this.editableEmailContactAlias);
            }
        },
        onSelectEmailAudience(item) {
            this.$router.push({ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, emailAudienceId: item.email_audience_id } });
        },
    },
    mounted() {
        this.loadAccount();
        this.loadEmailContact();
        this.loadTopicList();
    },
};
</script>
